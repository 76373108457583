import React, { useRef, useState } from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { db } from "../services/firebase";
import { useCollectionData } from "react-firebase-hooks/firestore";
import "../css/Common.css";
import "../css/TakeList.css";

//https://stackoverflow.com/a/21984136/3853668
function _calculateAge(birthday) {
	// birthday is a date
	var ageDifMs = Date.now() - birthday.getTime();
	var ageDate = new Date(ageDifMs); // miliseconds from epoch
	return Math.abs(ageDate.getUTCFullYear() - 1970);
}

export default function Archive() {
	const referralRef = db.collection("visits");
	const [referrals] = useCollectionData(
		referralRef.where("archived", "==", true),
		{
			idField: "id",
		}
	);

	return (
		<Table striped bordered hover size="sm" className="container">
			<thead>
				<tr>
					<th>Name</th>
					<th>Hospital Number</th>
					<th>Age</th>
					<th>Problem / Diagnosis</th>
					<th>NEWS</th>
					<th>Referred By</th>
					<th>Location</th>
					<th>Clerked By</th>
					<th>Cons. Review</th>
				</tr>
			</thead>
			<tbody>
				{referrals &&
					referrals.map((referral) => (
						<Referral key={referral.id} referral={referral} />
					))}
			</tbody>
		</Table>
	);
}

function Referral(props) {
	let referral = {};
	const returnedTarget = Object.assign(referral, props.referral);
	referral.dob = new Date(referral.dob);

	const {
		id,
		clerked,
		consReview,
		diagnosis,
		location,
		news,
		referred,
		dob,
		name,
		hospitalNumer,
	} = referral;

	return (
		<tr key={id}>
			<td>{name}</td>
			<td>{hospitalNumer}</td>
			<td>{_calculateAge(dob)}</td>
			<td>{diagnosis}</td>
			<td>{news}</td>
			<td>{referred}</td>
			<td>{location}</td>
			<td>{clerked}</td>
			<td>{consReview}</td>
		</tr>
	);
}
