import React, { useState, useEffect } from "react";
import { Input, Button, Row, Col, Textarea } from "nhsuk-react-components";
import { db } from "../services/firebase";
import { useParams, useHistory } from "react-router-dom";
import "../css/Common.css";

export default function AddPt(props) {
	const history = useHistory();
	const [referral, setReferralForm] = useState({});

	return (
		<div className="container">
			<div style={{ padding: "48px" }}>
				<Row>
					<h2>Add Patient</h2>
					<Col width="one-half">
						<Input
							onChange={(e) =>
								setReferralForm((prevReferral) => {
									return { ...prevReferral, name: e.target.value };
								})
							}
							label="Name"
							value={referral.name}
						/>
						<Input
							onChange={(e) =>
								setReferralForm((prevReferral) => {
									return { ...prevReferral, hospitalNumer: e.target.value };
								})
							}
							label="Hospital Number"
							value={referral.hospitalNumer}
						/>
						<Input
							onChange={(e) =>
								setReferralForm((prevReferral) => {
									return { ...prevReferral, dob: formatDate(e.target.value) };
								})
							}
							label="Date of Birth"
							type="date"
							value={formatDate(referral.dob)}
						/>
						<Textarea
							onChange={(e) =>
								setReferralForm((prevReferral) => {
									return { ...prevReferral, diagnosis: e.target.value };
								})
							}
							label="Problem / Diagnosis"
							rows={5}
							value={referral.diagnosis}
						/>
					</Col>
					<Col width="one-half">
						<Input
							onChange={(e) =>
								setReferralForm((prevReferral) => {
									return { ...prevReferral, news: e.target.value };
								})
							}
							label="NEWS"
							type="number"
							value={referral.news}
						/>
						<Input
							onChange={(e) =>
								setReferralForm((prevReferral) => {
									return { ...prevReferral, referred: e.target.value };
								})
							}
							label="Referred By"
							value={referral.referred}
						/>
						<Input
							onChange={(e) =>
								setReferralForm((prevReferral) => {
									return { ...prevReferral, location: e.target.value };
								})
							}
							label="Location"
							value={referral.location}
						/>
						<Input
							onChange={(e) =>
								setReferralForm((prevReferral) => {
									return { ...prevReferral, clerked: e.target.value };
								})
							}
							label="Clerked By"
							value={referral.clerked}
						/>
						<Input
							onChange={(e) =>
								setReferralForm((prevReferral) => {
									return { ...prevReferral, consReview: e.target.value };
								})
							}
							label="Consultant Review"
							value={referral.consReview}
						/>

						<Button
							style={{ float: "right", marginLeft: "12px" }}
							onClick={() => {
								db.collection("visits").add({...referral, archived: false}).then(() => history.push("/list"));
							}}
						>
							Apply Changes
						</Button>
					</Col>
				</Row>
			</div>
		</div>
	);
}

function formatDate(date) {
	var d = new Date(date),
		month = "" + (d.getMonth() + 1),
		day = "" + d.getDate(),
		year = d.getFullYear();

	if (month.length < 2) month = "0" + month;
	if (day.length < 2) day = "0" + day;

	return [year, month, day].join("-");
}
