import React from "react";
import { Button, Table } from "nhsuk-react-components";
import { Link, useHistory } from "react-router-dom";
import { db } from "../services/firebase";
import { useCollectionData } from "react-firebase-hooks/firestore";
import "../css/Common.css";
import "../css/TakeList.css";

//https://stackoverflow.com/a/21984136/3853668
function _calculateAge(birthday) {
	// birthday is a date
	var ageDifMs = Date.now() - birthday.getTime();
	var ageDate = new Date(ageDifMs); // miliseconds from epoch
	return Math.abs(ageDate.getUTCFullYear() - 1970);
}

export default function TakeList() {
	const referralRef = db.collection("visits");
	const [referrals] = useCollectionData(
		referralRef.where("archived", "==", false),
		{
			idField: "id",
		}
	);
	const history = useHistory();

	return (
		<>
			<div className="container">
				<h3>Medical Take for {formatDate(new Date())}</h3>

				<div className="taketeam">
					<div>Cons. Dr Abc / Blp 0000</div>
					<div>SHO Dr Bcd / Blp 0001</div>
					<div>SHO Dr Cde / Blp 0002</div>
					<div>FY1 Dr Def / Blp 0003</div>
				</div>

				<Table
					striped
					bordered
					hover
					size="sm"
					style={{ marginBottom: "16px" }}
				>
					<thead>
						<tr>
							<th>Name</th>
							<th>Hospital Number</th>
							<th>Age</th>
							<th>Problem / Diagnosis</th>
							<th>NEWS</th>
							<th>Referred By</th>
							<th>Location</th>
							<th>Clerked By</th>
							<th>Cons. Review</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{referrals &&
							referrals.map((referral) => (
								<Referral key={referral.id} referral={referral} />
							))}
					</tbody>
				</Table>
				<Button
					style={{ margin: "12px" }}
					onClick={() => alert("Not yet implemented")}
				>
					Print List
				</Button>
				<Button
					style={{ margin: "12px" }}
					onClick={() => history.push("/create")}
				>
					Add Patient
				</Button>
			</div>
		</>
	);
}

function Referral(props) {
	let referral = {};
	const returnedTarget = Object.assign(referral, props.referral);
	referral.dob = new Date(referral.dob);

	const {
		id,
		clerked,
		consReview,
		diagnosis,
		location,
		news,
		referred,
		dob,
		name,
		hospitalNumer,
	} = referral;

	return (
		<tr key={id}>
			<td>{name}</td>
			<td>{hospitalNumer}</td>
			<td>{_calculateAge(dob)}</td>
			<td>{diagnosis}</td>
			<td>{news}</td>
			<td>{referred}</td>
			<td>{location}</td>
			<td>{clerked}</td>
			<td>{consReview}</td>
			<td>
				<Link
					style={{ fontWeight: "bold", color: "#006747" }}
					to={{ pathname: `/edit/${id}`, data: { referral: props.referral } }}
				>
					Edit
				</Link>
			</td>
		</tr>
	);
}

function formatDate(date) {
	var d = new Date(date),
		month = "" + (d.getMonth() + 1),
		day = "" + d.getDate(),
		year = d.getFullYear();

	if (month.length < 2) month = "0" + month;
	if (day.length < 2) day = "0" + day;

	return [day, month, year].join("/");
}
