import firebase from "firebase/app";
import "firebase/firestore";

var firebaseConfig = {
	apiKey: "AIzaSyCNUW5H4mCphOeGzG5nrvoR7i-Qz8KbywA",
	authDomain: "rsch-eau-take-list.firebaseapp.com",
	projectId: "rsch-eau-take-list",
	storageBucket: "rsch-eau-take-list.appspot.com",
	messagingSenderId: "607061247496",
	appId: "1:607061247496:web:e54543b9e215727c89b3bd",
	measurementId: "G-EQ1H0PJP6J"
};

firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();

export { firebase, db };