import React from "react";
import { Footer as NHSFooter } from "nhsuk-react-components";

export default function Footer() {
	return (
		<div>
			<NHSFooter>
				<NHSFooter.List style={{ "width": "30%" }}>
					<NHSFooter.ListItem href="https://www.royalsurrey.nhs.uk/">Royal Surrey Main Site</NHSFooter.ListItem>
				</NHSFooter.List>
				<NHSFooter.Copyright style={{ "width": "70%" }}>&copy; Reid Technologies Ltd</NHSFooter.Copyright>
			</NHSFooter>
		</div>
	)
}
