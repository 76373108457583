import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "../node_modules/nhsuk-frontend/packages/nhsuk.scss";

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById("root")
);