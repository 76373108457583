import React from "react";
import { Header as NHSHeader } from "nhsuk-react-components";
import { Link, useHistory } from "react-router-dom";

export default function Header() {
	const history = useHistory();

	function handleLogout(e) {}

	function navigate(path) {
		console.log(path);
		history.push(path);
	}

	return (
		<>
			<NHSHeader
				orgName="Royal Surrey"
				orgDescriptor="NHS Foundation Trust"
				white
			>
				<NHSHeader.Container>
					<NHSHeader.Logo href="/" />
					<NHSHeader.Content>
						<div style={{ display: "grid" }}>
							<NHSHeader.MenuToggle />
							<h3 style={{ margin: "0", paddingTop: "20px", textAlign: "end" }}>
								Medical Take List
							</h3>
							<span>
								Logged in as{" "}
								<span style={{ textAlign: "right", fontStyle: "italic" }}>
									Matthew Reid
								</span>
							</span>
						</div>
					</NHSHeader.Content>
				</NHSHeader.Container>
				<NHSHeader.Nav>
					<NHSHeader.NavItem
						style={{ cursor: "pointer" }}
						onClick={() => navigate("/list")}
					>
						Medical Take List
					</NHSHeader.NavItem>
					<NHSHeader.NavItem
						style={{ cursor: "pointer" }}
						onClick={() => navigate("/archive")}
					>
						Archived Lists
					</NHSHeader.NavItem>
					<NHSHeader.NavItem
						style={{ cursor: "pointer" }}
						onClick={() => navigate("/settings")}
					>
						Settings
					</NHSHeader.NavItem>
					<NHSHeader.NavItem
						style={{ cursor: "pointer" }}
						onClick={handleLogout}
					>
						Logout
					</NHSHeader.NavItem>
				</NHSHeader.Nav>
			</NHSHeader>
		</>
	);
}
