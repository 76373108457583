import Header from "./components/Header";
import Footer from "./components/Footer";
import TakeList from "./components/TakeList";
import EditPt from "./components/EditPt";
import AddPt from "./components/AddPt";
import Archive from "./components/Archive";
import { Container } from "nhsuk-react-components";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";

function App() {
	return (
		<Router>
			<Header />
			<Container style={{ maxWidth: "1500px" }}>
				<Switch>
					<Route exact path="/">
						<Redirect to="/list" />
					</Route>
					<Route
						exact
						path="/edit/:id"
						render={(props) => <EditPt {...props} />}
					/>
					<Route path="/list">
						<TakeList />
					</Route>
					<Route path="/archive">
						<Archive />
					</Route>
					<Route path="/create">
						<AddPt />
					</Route>
				</Switch>
			</Container>

			<Footer />
		</Router>
	);
}

export default App;
